import {
  // eslint-disable-next-line no-restricted-imports
  api,
  ErrorResponseApiDto,
  PurinaAssignQualifierRequestApiDto,
} from '@b2x/storefront-api-js-client/src';
import { AxiosError } from 'axios';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useCustomApi = () => {
  const { apiRequest } = useApiRequest();

  const purinaAssignQualifier = React.useCallback(
    (data: PurinaAssignQualifierRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.custom.purinaAssignQualifier(data), {
        silent: false,
        suppressErrorModal: true,
        ...config,
      })
        .then((response) => {
          if (response.data.success === true) {
            console.log('Qualifica assegnata');
          } else {
            console.log('Qualifica già assegnata');
          }
          //return response;
        })
        .catch((error: AxiosError<ErrorResponseApiDto>) => {
          //throw error;
          console.log(error);
        }),
    [apiRequest]
  );

  return { purinaAssignQualifier };
};
